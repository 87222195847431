.site-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-centered-page {
  margin: auto;
  border: 1px solid $color-grey;
  background: $color-white;
  width: 500px;
  max-width: 95%;
}

.site-main {
  background: $color-white;
  padding: 1em;
  position: relative;
  flex-grow: 1;

  &.site-main--transparent {
    background: $color-greyAlt;
  }

  &::after {
    clear: both;
    content: "";
    display: table;
  }

  address {
    font-style: normal;
  }

  p,
  ul,
  ol {
    margin-bottom: 1em;
  }

  ol {
    list-style-type: decimal;
    padding: 0 0 0 1.5em;
  }

  li {
    padding: 0 0 0.5em;
    position: relative;

    > :only-child {
      margin: 0;
    }
  }

  ul > li {
    list-style: none;

    &::before {
      background: url("../../vendors/images/icon-door.png") left center
        no-repeat;
      content: "";
      display: inline-flex;
      height: 1em;
      margin-right: 0.5em;
      width: 1em;
    }
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  hr {
    border: 0;
    background: #ddd;
    height: 1px;
    margin: 10px 0;
  }
}

.site-section {
  background: $color-greyAlt;
  margin: 3em 0;
  padding: 1em 1em 1px;
  border-radius: 0.4em;
}
