*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  min-width: 320px;
  overflow: auto;
  overflow-x: hidden;
}

:root {
  font-size: 16px;
  line-height: 1.5;
  -webkit-overflow-scrolling: touch;
  font-family: $font-primary;

  body {
    background: $color-greyAlt;
    color: $color-greyText;
    margin: 0 auto;
    max-width: 960px;
    min-height: 100vh;
    min-width: 280px;
    position: static !important;
    overflow-x: hidden;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  background: $color-white;
  margin: 16px 0 32px;
  max-width: 100%;
  width: 100%;
}

th,
td {
  border-bottom: 1px solid $color-grey;
  padding: 0.5em;
  vertical-align: middle;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

th {
  font-size: 1.1em;
  font-weight: 600;
  text-align: left;
}

img:not([src$=".svg"]),
iframe {
  max-width: 100%;
}

b {
  font-weight: bold;
}

[hidden] {
  display: none;
  visibility: hidden;
}
