.site-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $color-white;
  border-bottom: 1px solid $color-red;
  padding: 1em;
  width: 100%;

  &::after {
    clear: both;
    content: "";
    display: table;
  }

  .logo {
    width: 185px;

    @media (max-width: 600px) {
      display: block;
      margin: 0 auto;
    }

    img {
      margin: 0;
      width: 11.5em;
    }
  }
}

.site-nav {
  flex-grow: 1;
  font-size: 1.4em;
  font-family: $font-secondary;
  margin: 16px;

  p,
  li {
    margin: 0 0.2em;
  }

  .site-nav__user {
    display: flex;
    justify-content: center;
  }

  .site-nav__links {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;
    vertical-align: middle;

    @media (max-width: 600px) {
      justify-content: center;
    }
  }

  li {
    background: none !important;

    a {
      display: block;
      overflow: hidden;
      padding: 0.1em 0.3em;
      position: relative;
    }

    a:hover {
      text-decoration: none;
    }
  }

  li:first-child a {
    padding-left: 0;
  }

  li:last-child a {
    padding-right: 0;
  }
}
