/* https://google-webfonts-helper.herokuapp.com/fonts */

@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../../vendors/fonts/josefin-sans-v17-latin-regular.woff2")
      format("woff2"),
    url("../../vendors/fonts/josefin-sans-v17-latin-regular.woff")
      format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../../vendors/fonts/open-sans-v18-latin-regular.woff2") format("woff2"),
    url("../../vendors/fonts/open-sans-v18-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local(""),
    url("../../vendors/fonts/open-sans-v18-latin-600.woff2") format("woff2"),
    url("../../vendors/fonts/open-sans-v18-latin-600.woff") format("woff");
}

@mixin header-styles {
  color: $color-greyHeader;
  font-family: $font-secondary;
  line-height: 1;
  margin-top: 0.5em;
  margin-bottom: 1em;

  &:not(:first-child) {
    margin-top: 1.5em;
  }
}

h1 {
  @include header-styles;

  font-size: 2em;
}

h2 {
  @include header-styles;

  font-size: 1.75em;
}

h3 {
  @include header-styles;

  font-size: 1.5em;
}

h4 {
  @include header-styles;

  font-size: 1.25em;
}

h5 {
  @include header-styles;

  font-size: 1em;
}

h6 {
  @include header-styles;

  font-size: 0.8em;
}

p,
li,
a,
address,
label,
input,
select,
textarea,
button {
  color: $color-greyText;
}

ol,
ul {
  margin-left: 0.5em;
}

a {
  color: $color-red;
  text-decoration: none;

  &:active,
  &:hover {
    outline: 0;
    text-decoration: underline;
  }
}

hr {
  border-bottom: 1px solid $color-grey;
  margin: 1em 0;
}

.button {
  background: $color-red;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 0 0 $color-redShade;
  color: $color-white !important;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2em;
  font-weight: normal;
  line-height: 1.2;
  max-width: 100% !important;
  outline: 0;
  padding: 0.2em 0.5em;
  text-align: center;
  text-decoration: none !important;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
}

.button[disabled="true"],
.button[disabled="disabled"] {
  background: $color-grey999;
  box-shadow: 0 2px 0 0 $color-greyShade;
  opacity: 0.8;
  cursor: not-allowed;
  pointer-events: none;
}
