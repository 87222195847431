@import "./theme";
@import "./reset";
@import "./typography";
@import "./site-layout";
@import "./site-header";
@import "./site-footer";

hr {
  clear: both;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.richtext-image {
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.richtext-image.left,
.richtext-image.right {
  @media (max-width: 639px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.richtext-image.left {
  @media (min-width: 640px) {
    float: left;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    max-width: 40%;
  }

  @media (min-width: 768px) {
    justify-content: center;
    max-width: 50%;
  }
}

.richtext-image.right {
  @media (min-width: 640px) {
    float: right;
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
    max-width: 40%;
  }

  @media (min-width: 768px) {
    justify-content: center;
    max-width: 50%;
  }
}
