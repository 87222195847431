.site-footer {
  font-family: $font-secondary;
  background: $color-greyAlt;
  color: $color-greyShade;
  font-size: 85%;
  padding: 1em;
  text-align: center;
  width: 100%;

  &::after {
    clear: both;
    content: "";
    display: table;
  }

  ul {
    font-size: 1.2em;
    margin: 0 0 0.8em;
  }

  li {
    display: inline-block;
    margin: 0 0.4em;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}
